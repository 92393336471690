var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contenedor",attrs:{"id":"salary"}},[_c('loader',{attrs:{"show":_vm.show}}),_c('sesion',{attrs:{"expiration":_vm.expiration}}),_c('Sidebar'),_c('v-container',{staticClass:"base text-center",attrs:{"fluid":""}},[_c('v-row',{staticClass:"titulo ml-10 mt-5",attrs:{"no-gutters":""}},[_vm._v("GUIA I")]),_c('v-row',{staticClass:"mr-12 mb-4",attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"5","xs":"12"}},[_c('v-card-title',[_c('router-link',{attrs:{"to":"/addQuizz","id":"nuevaPreguntaempleado"}},[_c('button',{staticClass:"breakSearch botonAmarillo",staticStyle:{"font-size":"0.70rem !important"}},[_vm._v(" Asignación de Cuestionario ")])]),_c('v-text-field',{staticClass:"mr-2",attrs:{"append-icon":"search","label":"Buscador","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-row',{staticClass:"datos pa-0",staticStyle:{"height":"auto !important"},attrs:{"no-gutters":""}},[_c('v-card',{staticStyle:{"width":"100%","background":"rgb(199, 195, 199) !important"}},[_c('v-data-table',{staticClass:"elevation-2 text--dark",attrs:{"headers":_vm.headers,"items":_vm.questions,"search":_vm.search,"loading-text":"Cargando datos... Por favor espere","items-per-page":5,"no-results-text":'No se encontraron incidencias',"no-data-text":'No hay datos',"footer-props":{
            'items-per-page-text': 'Guías por página',
            'items-per-page': [5, 10, 15],
            'items-per-page-all-text': 'Todos',
            'items-per-page-options': [5, 10],
            'loading-text': 'Obteniendo datos... Por favor espere',
            'no-data-text': 'No hay datos...',
            'no-results-text': 'No se encontraron incidencias',
          }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"#ffffff","icon":"warning"}},[_vm._v("No hay datos en sistema ..")])]},proxy:true},{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id,class:{ selectedRow: item === _vm.selectedItem }},[_c('td',{staticClass:"text-sm-center"},[_vm._v(_vm._s(item.id))]),_c('td',{staticClass:"text-sm-center"},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(item.empleado.nombre + " " + item.empleado.apellidoPaterno)+" ")]},null,item.empleado)],2),_c('td',{staticClass:"text-sm-center"},[_vm._v(" "+_vm._s(item.fechaInicio.substr(0, 10))+" ")]),_c('td',{staticClass:"text-sm-center"},[_vm._v(" "+_vm._s(item.fechaRespondido != null ? item.fechaRespondido.substr(0, 10) : "")+" ")]),_c('td',{staticClass:"justify-center layout px-0"},[(item.activa == true)?_c('v-icon',{staticClass:"mr-6 mt-1",attrs:{"color":"#717171","size":"24"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("fa-edit")]):_vm._e(),(item.activa == false)?_c('v-icon',{staticClass:"ml-9",attrs:{"color":"red","size":"26"},on:{"click":function($event){return _vm.consultaDatos(item.id)}}},[_vm._v("far fa-file-pdf")]):_vm._e()],1)])}),0)]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }